import { toast } from 'react-toastify'

type Action = LoginAction | LogoutAction | UserAction | WialonConnectAction | WialonDisconnectAction

interface LoginAction {
  type: 'login'
  payload: string
}

interface LogoutAction {
  type: 'logout'
}

interface UserAction {
  type: 'set_user'
  payload: User
}

interface WialonDisconnectAction {
  type: 'disconnect_wialon'
}

interface WialonConnectAction {
  type: 'connect_wialon'
  payload: string
}

interface User {
  email: string
  company: string
  wialon_token?: string
}

interface State {
  isAuthenticated: boolean
  user?: User
  token?: string
}

const reducer = (state: State, action: Action): State => {
  console.log('Reducing:', action, state)
  switch (action.type) {
    case 'login':
      localStorage.setItem('JWT', action.payload)

      toast.info('Connecté', { toastId: action.type })
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true,
      }
    case 'set_user':
      return {
        ...state,
        user: action.payload,
      }
    case 'logout':
      localStorage.removeItem('JWT')
      const { user, token, ...newState } = state

      toast.info('Déconnecté', { toastId: action.type })
      return {
        ...newState,
        isAuthenticated: false,
      }
    case 'connect_wialon':
      toast.info('Connecté à Wialon', { toastId: action.type })

      if (!state.user) {
        console.error('Fatal state error.')
      } else {
        return {
          ...state,
          user: {
            ...state.user,
            wialon_token: action.payload,
          },
        }
      }
      return state
    case 'disconnect_wialon':
      delete state.user?.wialon_token

      toast.info('Vous avez été déconnecté de Wialon', { toastId: action.type })

      return state
    default:
      return state
  }
}

export default reducer
export type { State, Action }
