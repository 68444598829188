import React, { FormEvent, useEffect, useState } from 'react'
import { useContext } from 'react'
import { fetchApi } from '../utils/auth'
import { AppContext } from '../utils/store'
import { toast } from 'react-toastify'
import Button from './Button'
import Card from './Card'
import CheckBox from './CheckBox'
import SelectField from './SelectField'
import TextField from './TextField'
import { useInterval } from '../utils/useTimeout'
import { getDriverIds, getUnitGroups, getUserInfo } from '../utils/wialon'
import Header from './Header'
import Select from 'react-select'

interface OccupationProp {}

interface Group {
  label: string
  value: number
}

enum Format {
  Pdf,
  Excel,
}

interface ExportFormat {
  label: string
  value: Format
}

const Occupation = (prop: OccupationProp) => {
  /**
   * Form fields
   */
  const [fileName, setFileName] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [sendEmail, setSendEmail] = useState(false)
  const [download, setDownload] = useState(false)

  /**
   * Form error fields
   */
  const [fileNameError, setFileNameError] = useState('')
  const [fromError, setFromError] = useState('')
  const [toError, setToError] = useState('')
  const [error, setError] = useState('')

  const [groupOptions, setGroupOptions] = useState<Group[]>([])
  const [group, setGroup] = useState<Group[]>([])
  const [reportId, setReportId] = useState(-1)

  // Format
  const [exportFormat, setExportFormat] = useState<ExportFormat>({
    value: Format.Excel,
    label: 'Excel',
  })

  const { state } = useContext(AppContext)

  const getReport = async (event: FormEvent) => {
    let returnEarly = false
    event.preventDefault()

    setFileNameError('')
    setFromError('')
    setToError('')

    //TODO(nwmqpa): Add proper validation
    if (fileName === '') {
      setFileNameError('Le nom du fichier ne peut pas contenir de caracères spéciaux ni être vide.')
      returnEarly = true
    }

    if (from === to) {
      setToError('Les dates ne peuvent pas être les memes.')
      returnEarly = true
    }

    let fromDate = Math.floor(Date.parse(`${from}T00:00:00`) / 1000)

    if (isNaN(fromDate)) {
      setFromError('Le format est incorrect.')
      returnEarly = true
    }

    let toDate = Math.floor(Date.parse(`${to}T23:59:59`) / 1000)

    if (isNaN(toDate)) {
      setToError('Le format est incorrect.')
      returnEarly = true
    }

    if (!sendEmail && !download) {
      toast.error('Une des deux options doit être sélectionnée.')
      returnEarly = true
    }

    if (returnEarly) {
      return
    }

    try {
      const response = await fetchApi(
        '/report',
        {
          method: 'POST',
          body: JSON.stringify({
            isExcel: exportFormat.value === Format.Excel,
            from: fromDate,
            to: toDate,
            reportTemplateId: 2,
            sendEmail,
            fileName,
            groups: group.map((g) => g.value),
          }),
        },
        true
      ).then((res: any) => res.json())

      if (response.error) {
        setError(response.error) //TODO(nwmqpa): Traduce error to French.
        return
      }
      setReportId(response.reportId)
      toast.info('Votre requête de rapport a bien été prise en compte. Veuillez patienter.')
    } catch (err) {
      toast.error('Impossible de demander un rapport, veuillez réessayer plus tard.')
      console.error(err)
    }
  }

  const saveReport = (link: string) => {
    let fileName = link.split('/')[link.split('/').length - 1]
    fetch(link, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode?.removeChild(link)
      })
  }

  useEffect(() => {
    let fetchGroupsInfos = async (token: string) => {
      let userInfo = await getUserInfo(token)
      let untiGroups = await getUnitGroups(userInfo.eid)
      let driverIds = await getDriverIds(userInfo.eid, userInfo.user.bact)

      let options = driverIds
        .map((driverId) => ({ label: untiGroups[driverId], value: driverId }))
        .filter((item) => item.label !== undefined)

      setGroupOptions(options)
      setGroup(options)
    }

    if (state.user?.wialon_token !== undefined) {
      fetchGroupsInfos(state.user.wialon_token)
    }
  }, [state.user?.wialon_token])

  useInterval(
    (timeout?: NodeJS.Timeout) => {
      fetchApi(
        `/report?reportId=${reportId}`,
        {
          method: 'GET',
        },
        true
      ).then(async (data) => {
        let jsonData = await data.json()

        let isProcessed = jsonData.status === 'PROCESSED'
        let isSent = jsonData.status === 'SENT'

        if (isSent) {
          setReportId(-1)
          if (sendEmail) {
            toast.success('Votre rapport vous a bien été envoyé par mail.')
          }
        }
        if (isProcessed) {
          if (!sendEmail) {
            setReportId(-1)
          }
          if (download) {
            toast.success('Votre rapport est disponible au téléchargement.')
            saveReport(jsonData.finalReport)
          }
        }
      })
    },
    reportId === -1 ? null : 1000
  )

  return (
    <>
      <Header />
      <Card
        description="Afin de générer le document, assurez-vous d’être connecté à Wialon puis d’avoir complété tous les champs ci-dessous."
        errorMsg={error}
      >
        <form onSubmit={(e) => getReport(e)}>
          <TextField
            label="Nom du fichier"
            type="text"
            errorMsg={fileNameError}
            onChange={(e) => setFileName(e.target.value)}
            value={fileName}
          />
          <SelectField<Group>
            label="Choix du Groupe Conducteur"
            options={groupOptions}
            onChange={(value, action) => setGroup(value as Group[])}
            value={group}
          />
          <TextField
            type="date"
            label="Date debut"
            errorMsg={fromError}
            onChange={(e) => setFrom(e.target.value)}
            value={from}
          />
          <TextField
            type="date"
            label="Date fin"
            errorMsg={toError}
            onChange={(e) => setTo(e.target.value)}
            value={to}
          />
          <div className="SelectField">
            <label>Choix du format d'export</label>
            <Select
              options={[
                { value: Format.Excel, label: 'Excel' },
                { value: Format.Pdf, label: 'PDF' },
              ]}
              defaultValue={{ value: Format.Pdf, label: 'Excel' }}
              onChange={(value, _action) => setExportFormat(value as ExportFormat)}
            />
          </div>
          <CheckBox
            label="Recevoir le document sur mon adresse mail"
            onChange={(e) => setSendEmail(e.target.checked)}
            checked={sendEmail}
          />
          <CheckBox
            label="Télécharger le document sur mon ordinateur"
            onChange={(e) => setDownload(e.target.checked)}
            checked={download}
          />
          <Button type="submit">Générer un rapport</Button>
        </form>
      </Card>
    </>
  )
}

export default Occupation
