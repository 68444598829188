import * as EmailValidator from 'email-validator';

const passwordReg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+><?])(?=.{8,})')
const passwordValidator = (password: string): boolean => passwordReg.test(password)

const emailValidator = (email: string): boolean => EmailValidator.validate(email)

const codeReg = new RegExp('^[0-9]{6}$')
const codeValidator = (email: string): boolean => codeReg.test(email)

export { passwordValidator, emailValidator, codeValidator }
