import React, { FormEvent } from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../../Components/Button'
import Card from '../../Components/Card'
import Header from '../../Components/Header'
import TextField from '../../Components/TextField'
import { fetchApi } from '../../utils/auth'

import './ForgotPassword.css'

interface ForgotPasswordProp {}

const ForgotPassword = (prop: ForgotPasswordProp) => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const resetPassword = async (event: FormEvent) => {
    event.preventDefault()

    try {
      const res = await fetchApi(
        '/user/forgot-password',
        {
          method: 'POST',
          body: JSON.stringify({
            email: email,
          }),
        },
        false
      ).then((res) => res.json())

      if (res.error) {
        setError(res.error) // TODO(Afourcat): Translate to french.
      }

      toast.success(`Email envoyé a: ${email}`)
      history.push(`/reset-password?email=${email}`)
    } catch (err) {
      setError('Erreur du serveur.')
      console.error(err)
    }
  }

  return (
    <>
      <Header />
      <div className="Page">
        <Card
          title="Vous avez oublié votre mot de passe ?"
          description="Nous allons vous envoyer un message pour vous aider à réinitialiser votre mot de passe."
          errorMsg={error}
        >
          <form onSubmit={(e) => resetPassword(e)}>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              label="Email"
              errorMsg={error}
            />
            <Button type="submit">Envoyer l'email</Button>
            <Link to="/login">Retourner a la page de connexion</Link>
          </form>
        </Card>
      </div>
    </>
  )
}

export default ForgotPassword
