import React from 'react'
import './Card.css'

interface CardProp {
  title?: string
  errorMsg?: string
  description?: string
  children?: JSX.Element | JSX.Element[] | string | string[]
}

const Card = (prop: CardProp) => {
  return (
    <div className="Card">
      {prop.title && <h1>{prop.title}</h1>}
      {prop.description && <div className="CardDescription">{prop.description}</div>}
      {prop.errorMsg && <p className="CardError">{prop.errorMsg}</p>}
      {prop.children}
    </div>
  )
}

export default Card
