import React, { ChangeEventHandler } from 'react'
import './TextField.css'

interface TextFieldProp {
  type: string
  label: string
  value?: string | number
  onChange?: ChangeEventHandler<HTMLInputElement>
  errorMsg?: string
}

const TextField = (prop: TextFieldProp) => {
  return (
    <div className="TextField">
      <label>{prop.label}</label>
      <input value={prop.value} onChange={prop.onChange} type={prop.type} />
      {prop.errorMsg && <p>{prop.errorMsg}</p>}
    </div>
  )
}

export default TextField
