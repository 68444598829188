import React, { FormEvent } from 'react'
import { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../../Components/Button'
import Card from '../../Components/Card'
import Header from '../../Components/Header'
import TextField from '../../Components/TextField'
import { fetchApi } from '../../utils/auth'
import { codeValidator, passwordValidator } from '../../utils/validators'

import './ResetPassword.css'

interface ResetPasswordProp {}

const ResetPassword = (prop: ResetPasswordProp) => {
  const location = useLocation()
  const history = useHistory()

  const codeSearch = new URLSearchParams(location.search).get('code')
  const emailSearch = new URLSearchParams(location.search).get('email')

  const [email] = useState(emailSearch || '') // TODO(Afourcat): Discuss
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [code, setCode] = useState(codeSearch || '')
  const [codeError, setCodeError] = useState('')

  const resetPassword = async (event: FormEvent) => {
    event.preventDefault()

    if (!codeValidator(code)) {
      setCodeError('Code invalide')
      return
    } else if (!passwordValidator(password)) {
      setPasswordError('Mot de passe invalide')
      return
    } else if (password === '' || code === '') {
      toast.error('Le formulaire doit être complet')
      return
    }

    try {
      const response = await fetchApi(
        '/user/reset-password',
        {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            code: code,
            password: password,
          }),
        },
        false
      ).then((res) => res.json())

      if (response.error) {
        toast.error(response.error) // TODO(Afourcat): Translate to french.
        return
      }

      toast.success('Votre mot de passe à été réinitialiser')
      history.push('/login')
    } catch (err) {
      toast.error('Impossible de réinitialiser le mot de passe.')
      console.error(err)
    }
  }

  return (
    <>
      <Header />
      <div className="Page">
        <Card
          title="Réinitialiser votre mot de passe"
          description="Veuillez définir un nouveau mot de passe pour votre compte. Il doit contenir 8 caracères minimum et être composé de majuscules, chiffres et caractères spéciaux."
        >
          <form onSubmit={(e) => resetPassword(e)}>
            <TextField
              onChange={(e) => setCode(e.target.value)}
              value={code}
              type="text"
              label="Code"
              errorMsg={codeError}
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              label="Nouveau Mot de passe"
              errorMsg={passwordError}
            />
            <Button type="submit">Réinitialiser le mot de passe</Button>
            <Link to="/login">Retourner a la page de connexion</Link>
          </form>
        </Card>
      </div>
    </>
  )
}

export default ResetPassword
