import React, { FormEvent } from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../../Components/Button'
import Card from '../../Components/Card'
import Header from '../../Components/Header'
import TextField from '../../Components/TextField'
import { fetchApi } from '../../utils/auth'
import { passwordValidator } from '../../utils/validators'

interface ChangePasswordProp {}

const ChangePassword = (prop: ChangePasswordProp) => {
  const history = useHistory()
  const [error, setError] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')

  const changePassword = async (event: FormEvent) => {
    event.preventDefault()

    setError('')

    if (oldPassword === newPassword) {
      setError('Les mots de passes doivent être différents et valide.')
      return
    } else if (!passwordValidator(newPassword)) {
      setError('Mot de passe invalide.')
      return
    }

    try {
      const response = await fetchApi('/user/change-password', {
        method: 'POST',
        body: JSON.stringify({
          oldPassword: oldPassword,
          password: newPassword,
        }),
      }).then((res) => res.json())

      if (response.error) {
        toast.error(response.error) //TODO(Afourcat): Translate to french.
        return
      }

      toast.success('Mot de passe changé')
      history.push('/')
    } catch (err) {
      setError('Erreur du serveur')
      console.error(err)
    }
  }

  return (
    <>
      <Header />
      <div className="Page">
        <Card
          title="Vous voulez changer votre mot de passe ?"
          description="Veuillez définir un nouveau mot de passe pour votre compte. Il doit contenir 8 caracères minimum et être composé de majuscules, chiffres et caractères spéciaux."
        >
          <form onSubmit={changePassword}>
            <TextField
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              type="password"
              label="Ancien mot de passe"
            />
            <TextField
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              type="password"
              label="Nouveau mot de passe"
              errorMsg={error}
            />
            <Button type="submit">Changer le mot de passe</Button>
            <Link to="/">Retourner a la page d'acceuil</Link>
          </form>
        </Card>
      </div>
    </>
  )
}

export default ChangePassword
