import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react'
import { isAuthenticated } from './auth'
import reducer, { Action, State } from './reducer'

type Context = { state: State; dispatch: Dispatch<Action> }

const initialState: Context = {
  state: {
    isAuthenticated: isAuthenticated(),
  },
  dispatch: (_action) => {},
}

const AppContext = createContext(initialState)
const { Provider } = AppContext

const StateProvider = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState.state)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default StateProvider

export { AppContext }
