import Header from '../../Components/Header'
import TextField from '../../Components/TextField'
import Card from '../../Components/Card'
import Button from '../../Components/Button'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Login.css'
import { FormEvent, useContext, useState } from 'react'
import { AppContext } from '../../utils/store'
import { Link } from 'react-router-dom'
import { emailValidator, passwordValidator } from '../../utils/validators'
import { fetchApi } from '../../utils/auth'

interface LoginProp {}

const Login = (prop: LoginProp) => {
  const { dispatch } = useContext(AppContext)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [error, setError] = useState('')

  const login = async (event: FormEvent) => {
    event.preventDefault()

    setPasswordError('')
    setEmailError('')
    setError('')

    if (!emailValidator(email)) {
      setEmailError('Email invalide.')
      return
    }

    if (!passwordValidator(password)) {
      setPasswordError('Mot de passe invalide.')
      return
    }

    try {
      const response = await fetchApi(
        '/auth/login',
        {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        },
        false
      ).then((res) => res.json())

      if (response.error) {
        setError(response.error) //TODO(Afourcat): Traduce error to French.
        return
      }

      dispatch({ type: 'login', payload: response.token })
    } catch (err) {
      toast.error('Impossible de se connecter, reassayer plus tard.')
      console.error(err)
    }
  }

  return (
    <>
      <Header />
      <div className="Page">
        <Card title="Connexion à votre espace entreprise" errorMsg={error}>
          <form onSubmit={(e) => login(e)}>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              label="Email"
              errorMsg={emailError}
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              label="Password"
              errorMsg={passwordError}
            />
            <Button type="submit">Connexion</Button>
            <Link to="/forgot-password">Mot de passe oublié ?</Link>
          </form>
        </Card>
      </div>
    </>
  )
}

export default Login
