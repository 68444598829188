import React, { FormEvent } from 'react'

import Header from '../../Components/Header'
import TextField from '../../Components/TextField'
import Card from '../../Components/Card'
import Button from '../../Components/Button'

import './Validate.css'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { codeValidator, emailValidator, passwordValidator } from '../../utils/validators'
import { toast } from 'react-toastify'
import { fetchApi } from '../../utils/auth'

interface ValidateProp {}

const Validate = (prop: ValidateProp) => {
  const location = useLocation()
  const history = useHistory()

  const codeSearch = new URLSearchParams(location.search).get('code')
  const emailSearch = new URLSearchParams(location.search).get('email')

  const [code, setCode] = useState(codeSearch || '')
  const [codeError, setCodeError] = useState('')
  const [email, setEmail] = useState(emailSearch || '')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [error, setError] = useState('')

  const validate = async (event: FormEvent) => {
    event.preventDefault()
    setEmailError('')
    setPassword('')
    setCodeError('')

    if (!emailValidator(email)) {
      setEmailError('Email invalide')
      return
    } else if (!codeValidator(code)) {
      setCodeError('Le code est invalide')
      return
    } else if (!passwordValidator(password)) {
      setPasswordError('Mot de passe invalide')
      return
    } else if (email === '' || password === '' || code === '') {
      setError('Tous les champs doivent être valide et rempli.')
      return
    }

    try {
      const response = await fetchApi(
        '/auth/validate',
        {
          method: 'POST',
          body: JSON.stringify({
            code: code,
            email: email,
            password: password,
          }),
        },
        false
      ).then((res) => res.json())

      if (response.error) {
        setError(response.error) // TODO(Afourcat): Translate to french.
        return
      }

      history.push('/login')
    } catch (err: any) {
      toast.error("Impossible de valider le compte pour l'instant, ressayer plus tard.")
      console.error(err.message)
    }
  }

  return (
    <>
      <Header />
      <div className="Page">
        <Card
          title="Validez votre compte"
          description="Afin de valider votre compte, choisissez un mot de passe de 8 caractères minimum contenant au moins une majuscule, un chiffre et un caractère spécial."
          errorMsg={error}
        >
          <form onSubmit={(e) => validate(e)}>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              label="Email"
              errorMsg={emailError}
            />
            <TextField
              onChange={(e) => setCode(e.target.value)}
              value={code}
              type="text"
              label="Code"
              errorMsg={codeError}
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              errorMsg={passwordError}
              type="password"
              label="Nouveau mot de passe"
            />
            <Button type="submit">Valider</Button>
          </form>
        </Card>
      </div>
    </>
  )
}

export default Validate
