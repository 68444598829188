import React from "react";

type TimeoutCallback = () => void;

export const useTimeout = (callback: TimeoutCallback, delay: number | null) => {
    const savedCallback = React.useRef<TimeoutCallback>();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current !== undefined) {
                savedCallback.current()
            }
        }
        if (delay !== null) {
            let id = setTimeout(tick, delay);
            return () => clearTimeout(id);
        }
    }, [delay]);
};

type IntervalCallback = (interval?: NodeJS.Timeout) => void;

export const useInterval = (callback: IntervalCallback, delay: number | null) => {
    const savedCallback = React.useRef<IntervalCallback>();
    const intervalRef = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current !== undefined) {
                savedCallback.current(intervalRef.current)
            }
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            intervalRef.current = id;
            return () => clearInterval(id);
        }
    }, [delay]);
};
