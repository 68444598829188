import React from 'react'
import { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchApi, isAdmin } from '../utils/auth'
import { AppContext } from '../utils/store'
import DropdownMenu from './DropdownMenu'

import './Header.css'

const LINKS = [
  {
    path: "/occupation",
    name: "Occupation",
    allowedCompany: "CandLandi"
  },
  {
    path: "/eco-fleet",
    name: "Eco Fleet",
    allowedCompany: "CandLandi"
  },
  {
    path: "/pointeuse",
    name: "Pointeuse",
    allowedCompany: "Cuenod"
  },
]

interface HeaderProp {}

const Header = (prop: HeaderProp) => {
  const { state } = useContext(AppContext)

  return state.isAuthenticated ? <LoggedHeader /> : <UnLoggedHeader />
}

const UnLoggedHeader = (prop: HeaderProp) => {
  return (
    <div className="Header">
      <nav className="NavBar">
        <img alt="Geoloc Logo" src={process.env.PUBLIC_URL + '/Logo.png'} />
        <span>Reporting MyGeoloc</span>
      </nav>
    </div>
  )
}

const LoggedHeader = (prop: HeaderProp) => {
  const { state, dispatch } = useContext(AppContext)
  const history = useHistory()

  const disconnectFromWialon = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()

    try {
      const res = await fetchApi('/wialon', {
        method: 'DELETE',
      }).then((res) => res.json())

      if (res.error) {
        toast.error(res.error) // TODO(Afourcat): Translate.
        return
      }

      dispatch({ type: 'disconnect_wialon' })
      history.push('/')
    } catch (err) {
      toast.error('Erreur du serveur.')
    }
  }

  return (
    <div className="LoggedHeader">
      <nav className="LoggedNavBar">
        <img alt="Geoloc Logo" src={process.env.PUBLIC_URL + '/Logo.png'} />
        {state.user?.wialon_token ? (
          <p>Vous êtes connecté à Wialon</p>
        ) : (
          <p>Connexion à Wialon requise</p>
        )}
        {
          LINKS.map((link) => {
            const { name, path, allowedCompany } = link;

            if (state.user?.company === allowedCompany || isAdmin(state.user?.company) ){
              return (
                <Link
                  className={history.location.pathname === path ? 'SelectedNavLink' : 'NavLink'}
                  to={path}
                >
                  { name }
                </Link>
              )
            }
            return <></>
          })
        }
        <DropdownMenu name="Options">
          <Link to="/change-password">Changer le mot de passe</Link>
          <div onClick={(_e) => dispatch({ type: 'logout' })}>Déconnexion</div>
          <>
            {state.user?.wialon_token && (
              <div onClick={disconnectFromWialon}>Déconnexion de Wialon</div>
            )}
          </>
        </DropdownMenu>
      </nav>
    </div>
  )
}

export default Header
