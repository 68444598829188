import { fetchWialonApi } from './auth'

export type UnitGroups = Record<number, string>

export interface UserInfo {
  eid: string
  user: {
    bact: number
  }
}

export const getDriverIds = async (sessionId: string, orgId: number, col: number = 2): Promise<number[]> => {
  return fetchWialonApi(
    new URLSearchParams({
      sid: sessionId,
      svc: 'report/get_report_data',
      params: JSON.stringify({ itemId: orgId, col: [col], flags: 0 }),
    })
  ).then(async (data) => {
    let jsonData = await data.json()
    let fields: { bind: { avl_unit_group: string[] } } = JSON.parse(jsonData[0]['p'])
    let driverIds = fields.bind.avl_unit_group.map((item: string) => parseInt(item, 10))
    return driverIds
  })
}

export const getDriverIdsFleet = async (sessionId: string, orgId: number): Promise<number[]> => {
  return fetchWialonApi(
    new URLSearchParams({
      sid: sessionId,
      svc: 'report/get_report_data',
      params: JSON.stringify({ itemId: orgId, col: [6], flags: 0 }),
    })
  ).then(async (data) => {
    let jsonData = await data.json()
    let fields = JSON.parse(jsonData[0]['p'])
    let driverIds = fields.bind.avl_drivers_group.map((item: string) => parseInt(item, 10))
    return driverIds
  })
}

export const getDriverIdsEcodrive = async (sessionId: string, orgId: number): Promise<number[]> => {
  return fetchWialonApi(
    new URLSearchParams({
      sid: sessionId,
      svc: 'report/get_report_data',
      params: JSON.stringify({ itemId: orgId, col: [26], flags: 0 }),
    })
  ).then(async (data) => {
    let jsonData = await data.json()
    let fields = JSON.parse(jsonData[0]['p'])
    let driverIds = fields.bind.avl_unit_group.map((item: string) => parseInt(item, 10))
    return driverIds
  })
}

export const getUnitGroups = async (sessionId: string): Promise<UnitGroups> => {
  return fetchWialonApi(
    new URLSearchParams({
      sid: sessionId,
      svc: 'core/search_items',
      params: JSON.stringify({
        spec: {
          itemsType: 'avl_unit_group',
          propName: 'sys_name',
          propValueMask: '*',
          sortType: '',
        },
        force: 1,
        flags: 5,
        from: 0,
        to: 4294967295,
      }),
    })
  ).then(async (data) => {
    let jsonData = await data.json()
    let unitGroups = jsonData.items.reduce((obj: UnitGroups, value: { nm: string; id: number }) => {
      obj[value.id] = value.nm
      return obj
    }, {})
    return unitGroups
  })
}

export const getUnitGroupsFleet = async (sessionId: string): Promise<UnitGroups> => {
  return fetchWialonApi(
    new URLSearchParams({
      sid: sessionId,
      svc: 'core/search_items',
      params: JSON.stringify({
        spec: {
          itemsType: 'avl_resource',
          propName: 'sys_name',
          propValueMask: '*',
          sortType: '',
        },
        force: 1,
        flags: 5,
        from: 0,
        to: 4294967295,
      }),
    })
  ).then(async (data) => {
    let jsonData = await data.json()
    let unitGroups = jsonData.items.reduce((obj: UnitGroups, value: { nm: string; id: number }) => {
      obj[value.id] = value.nm
      return obj
    }, {})
    return unitGroups
  })
}

export const getUserInfo = async (wialonToken: string): Promise<UserInfo> => {
  return fetchWialonApi(
    new URLSearchParams({
      svc: 'token/login',
      params: JSON.stringify({ token: wialonToken }),
    })
  ).then(async (data) => {
    let jsonData: UserInfo = await data.json()
    return jsonData
  })
}
