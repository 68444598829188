import React from 'react'
import './CheckBox.css'

interface CheckBoxProp {
  label: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  checked?: boolean
  disabled?: boolean
}

const CheckBox = (prop: CheckBoxProp) => {
  return (
    <span className="CheckBox">
      <input
        onChange={prop.onChange}
        type="checkbox"
        checked={prop.checked}
        disabled={prop.disabled}
      />
      <label>{prop.label}</label>
    </span>
  )
}

export default CheckBox
