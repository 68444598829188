import React, { MouseEventHandler } from 'react'
import './Button.css'

interface ButtonProp {
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: MouseEventHandler<HTMLButtonElement>
  children?: JSX.Element[] | JSX.Element | string | string[]
  className?: string
}

const Button = (prop: ButtonProp) => {
  return (
    <button
      type={prop.type}
      onClick={prop.onClick}
      className={`Button ${prop.className ? prop.className : ''}`}
    >
      {prop.children}
    </button>
  )
}

export default Button
