import React, { useState } from 'react'

import './DropdownMenu.css'

interface DropdownMenuProp {
  name: string
  children?: JSX.Element[] | JSX.Element
}

const DropdownMenu = (prop: DropdownMenuProp) => {
  const [activated, setActivated] = useState(false)

  return (
    <div className="DropdownMenu">
      <button onClick={(e) => setActivated(!activated)}>{prop.name}</button>
      {activated && <div className="DropdownContent">{prop.children}</div>}
    </div>
  )
}

export default DropdownMenu
