import React from 'react'
import { RouteProps } from 'react-router-dom'
import Button from './Button'
import Card from './Card'

interface ConnectToWialonProp extends RouteProps {}

const ConnectToWialon = (prop: ConnectToWialonProp) => {
  const redirectToWialon = async () => {
    const url = window.location.href

    console.log(url, prop.location)

    if (typeof window !== undefined) {
      window.location.href = `https://tracking.mygeoloc.ch/login.html?client_id=geoloc_platform&lang=en&redirect_uri=${url}`
    }
  }

  return (
    <Card
      title="Connexion à Wialon"
      description="Afin de pouvoir commencer à générer des rapports, merci de vous connecter à Wialon depuis le lien ci-dessous."
    >
      <Button onClick={(e) => redirectToWialon()}>Rediriger vers Wialon</Button>
    </Card>
  )
}

export default ConnectToWialon
