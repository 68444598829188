import { RouteProps, Route, Redirect } from 'react-router-dom'
import { isAdmin } from '../utils/auth';
import { State } from '../utils/reducer';

export type ProtectedRouteProps = {
  isAuthenticated: boolean
  authenticationPath: string
} & RouteProps

const ProtectedRoute = ({
  isAuthenticated,
  authenticationPath,
  ...routeProps
}: ProtectedRouteProps) => {
  if (isAuthenticated) {
    return <Route {...routeProps} />
  } else {
    console.log('Redirect to', authenticationPath)
    return <Redirect to={{ pathname: authenticationPath }} />
  }
}

const ProtectedRouteWialon = ({
  isAuthenticated,
  authenticationPath,
  ...routeProps
}: ProtectedRouteProps) => {
  if (isAuthenticated) {
    return <Route {...routeProps} />
  } else {
    console.log('Redirect to', authenticationPath)
    return <Redirect to={{ pathname: authenticationPath }} />
  }
}

export type ProtectedMultiTenantRouteWialonProps = {
  state: State
  allowedCompany: string,
  authenticationPath: string
} & RouteProps

const ProtectedMultiTenantRouteWialon = ({
  state,
  allowedCompany,
  authenticationPath,
  ...routeProps
}: ProtectedMultiTenantRouteWialonProps) => {
  const isAuthenticated = state.isAuthenticated && state.user?.wialon_token !== undefined;

  if (allowedCompany === state.user?.company || isAdmin(state.user?.company)) {
    return <ProtectedRouteWialon {...{ isAuthenticated, authenticationPath, ...routeProps }} />
  }
  return <Redirect to={{ pathname: authenticationPath }} />
}

export {
    ProtectedRoute,
    ProtectedRouteWialon,
    ProtectedMultiTenantRouteWialon
}
