import React from 'react'
import { RouteProps } from 'react-router-dom'
import Card from './Card'

interface ConnectedToWialonProp extends RouteProps {}

const ConnectedToWialon = (prop: ConnectedToWialonProp) => {
  return (
    <Card
      title="Vous êtes connecté à Wialon !"
      description="Pour générer un rapport, vous pouvez cliquer sur les onglets ci-dessus."
    >
    </Card>
  )
}

export default ConnectedToWialon
